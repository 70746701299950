body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: white;
  font-family: "MS Sans Serif", Tahoma, sans-serif;
}

.app-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  image-rendering: pixelated;
}

#robot-generator {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-text {
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  background: #000080 !important;
  font-family: "MS Sans Serif", Tahoma, sans-serif;
  padding: 16px !important;
  white-space: pre-line;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px #000000;
  max-width: 800px;
  margin: 0 auto 20px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
}

#trait-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
}

.trait-preview {
  margin-bottom: 20px;
  background: #c0c0c0;
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  padding: 8px;
  width: 100%;
}

.trait-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
  background: #808080;
  border: 2px solid;
  border-color: #404040 #ffffff #ffffff #404040;
  justify-content: center;
}

.trait-option {
  cursor: pointer;
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  padding: 4px;
  width: 100px;
  height: 100px;
  background: #c0c0c0;
}

.trait-option.selected {
  border-color: #808080 #ffffff #ffffff #808080;
  background: #000080;
}

.trait-option.selected p {
  color: white;
}

.trait-option img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: pixelated;
}

.trait-option p {
  margin: 4px 0 0;
  text-align: center;
  font-size: 12px;
  color: black;
}

.combined-preview {
  margin-top: 20px;
  background: #c0c0c0;
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  padding: 8px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
}

.preview-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* Makes it square */
  border: 2px solid;
  border-color: #404040 #ffffff #ffffff #404040;
  background: #808080;
}

.preview-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: pixelated;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.win98-button {
  font-family: "MS Sans Serif", Tahoma, sans-serif;
  font-size: 14px;
  padding: 6px 12px;
  margin: 4px;
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  background: #c0c0c0;
  color: black;
  cursor: pointer;
  outline: 1px solid #000000;
  text-decoration: none;
}

.win98-button:active {
  border-color: #808080 #ffffff #ffffff #808080;
}

.win98-button:disabled {
  color: #808080;
  text-shadow: 1px 1px #ffffff;
}

.social-links {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  z-index: 1000;
}

/* Wallet button override */
.wallet-adapter-button {
  font-family: "MS Sans Serif", Tahoma, sans-serif !important;
  background: #c0c0c0 !important;
  padding: 6px 12px !important;
  height: auto !important;
  color: black !important;
  border: 2px solid !important;
  border-color: #ffffff #808080 #808080 #ffffff !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  outline: 1px solid #000000;
}

.wallet-adapter-button:active {
  border-color: #808080 #ffffff #ffffff #808080 !important;
}

.wallet-adapter-button-trigger {
  background: #c0c0c0 !important;
}

/* Modal overrides */
.wallet-adapter-modal-wrapper {
  background: #c0c0c0 !important;
  border: 2px solid !important;
  border-color: #ffffff #808080 #808080 #ffffff !important;
  border-radius: 0 !important;
}

.wallet-adapter-modal-button-close {
  background: #c0c0c0 !important;
  border: 2px solid !important;
  border-color: #ffffff #808080 #808080 #ffffff !important;
  border-radius: 0 !important;
  margin: 8px !important;
}

.wallet-adapter-modal-title {
  color: black !important;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #808080;
  color: white;
  font-size: 12px;
  text-align: center;
}